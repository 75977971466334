<template>
  <div class="signin_page">
    <div class="content">
      <div class="signup_form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label class="form_title">
            <h2>手机号登录</h2>
          </el-form-item>
          <el-form-item label prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="请输入密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div class="tip">
            <router-link to="/resetPassword">找回密码</router-link>
          </div>
          <el-form-item>
            <el-button
              class="signin_btn"
              type="primary"
              @click="submitLoginForm('ruleForm')"
              >登 录</el-button
            >
          </el-form-item>
        </el-form>

        <div class="signin_right_now">
          没有账号，
          <router-link to="/signup">马上注册</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encrypt } from "@/utils";
import cookieOptions from "@/config/cookie.config";

export default {
  data() {
    return {
      activeNemuIndex: "1",
      ruleForm: {
        username: "",
        phone: "",
        password: "",
        code: "",
        card_num: "",
        card_password: ""
      },
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    submitLoginForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const reqData = {
            phone: this.ruleForm.phone,
            password: this.ruleForm.password
          };
          const res = await this.$axios.post("/api/user/login/", reqData);
          if (res.data.errno == 0) {
            localStorage.setItem('userInfo', JSON.stringify(res.data.results.user));
            this.$cookies.set(
              "yzy-token",
              encrypt(res.data.results.token)
            );
            this.$store.commit("SET_USER", res.data.results.user);
            // console.log('写入', this.$cookies.get("yzy-token"));
            if(res.data.results.user.point){
              this.$router.push({path: "/"});
            }else{
              this.$router.push({name: "LoginResult"});
            }
            
          } else {
            this.$toast(res.data.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelect(key, keyPath) {
      this.activeNemuIndex = key;
    },
    cardLoginSubmitForm() {}
  }
};
</script>

<style lang="scss" scoped>
.signin_page {
  overflow: hidden;
  background: #fff;
  min-height: 100vh;
  .content {
    padding-top: 0.2rem;
    .form_title {
      border-bottom: 1px solid#DCDFE6;
      margin-bottom: 0.5rem;
      h2 {
        font-size: 0.3rem;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        font-weight: 600;
        color: #444;
      }
    }
    .el-menu--horizontal {
      padding: 0 80px;
      border-bottom: solid 1px #e6e6e6;
      background: #fff;
      .el-menu-item:not(.is-disabled):hover {
        background: #fff;
      }
    }

    .signup_form {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 1rem;
      background: #fff;
      border-radius: 4px;
      .login_right_now {
        text-align: center;
      }
      .el-form-item {
        padding: 0 0.6rem;
      }
      .card_login {
        margin-top: 50px;
      }

      .tip {
        font-size: 0.24rem;
        line-height: 24px;
        text-align: right;
        margin-top: 4px;
        width: 100%;
        margin: 0 auto;
        cursor: pointer;
        padding: 0 0.6rem;
        color: #910000;
      }
      .el-button--primary {
        width: 100%;
      }
      .phone_code_wrap {
        position: relative;
        .get_phone_code {
          position: absolute;
          right: 10px;
          top: 0;
          color: #910000;
          cursor: pointer;
        }
      }
    }
    .signin_btn {
      margin-top: 30px;
    }
    .signin_right_now {
      text-align: center;
    }
  }
}
</style>
